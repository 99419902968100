import React, { useCallback, useEffect, useRef, useState } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { TweenLite } from 'gsap/all'
import { animationType, buildScrollTriggers, createAnimationType, doAnimate, nl2br, resetElements } from '../utils/functions'
import Jumpbutton from '../components/ux/buttons/jumpbutton/jumpbutton'
import { ScrollTrigger } from 'gsap/all'
import { Image, ImageRef } from '../components/ux/image'
import { connect } from 'react-redux'
// Bilder
import CloudMachinesIMacImage from './../images/cloud/Cloud_iMac.png'
import CloudMachinesIMacImageLarge from './../images/cloud/Cloud_iMac@2x.png'
import CloudMachinesIPhoneImage from './../images/cloud/Cloud_iPhone.png'
import CloudMachinesIPhoneImageLarge from './../images/cloud/Cloud_iPhone@2x.png'
import CloudShopMacbookImage from './../images/cloud/Shop_MacBook.png'
import CloudShopMacbookImageLarge from './../images/cloud/Shop_MacBook@2x.png'
import CloudShopIPhoneImage from './../images/cloud/Shop_iPhone.png'
import CloudShopIPhoneImageLarge from './../images/cloud/Shop_iPhone@2x.png'
// Bilder Mobile
import CloudMachinesImageMobile from './../images/cloud/cloud-machines.png'
import CloudMachinesImageMobileLarge from './../images/cloud/cloud-machines@2x.png'
import CloudShopImageMobile from './../images/cloud/cloud-shop.png'
import CloudShopImageMobileLarge from './../images/cloud/cloud-shop@2x.png'

function Cloud({ translations }) {
    const lightHolderRef = useRef();
    const swingerRef = useRef();

    const [rendered, setRendered] = useState(false)

    createAnimationType();

    // Scrollrefs
    const scrollRef1 = useRef();
    const scrollRef2 = useRef();
    const scrollRefs = [
        scrollRef1,
        scrollRef2
    ];

    // Animation Refs
    const imageRef = useRef();
    const h1Ref = useRef();
    const textRef = useRef();
    const imageRef2 = useRef();
    const h1Ref2 = useRef();
    const textRef2 = useRef();

    // Aktuelle Position, Status etc.
    let currentScrollPos = useRef(1);
    let animationActive = useRef(false);
    let clickable = useRef(true);
    let entered = useRef(false);


    // Animationen für jede Section
    const scrollEffect = useCallback((step) => {
        var durationFactor = 1;
        var delayFactor = .5;
        animationActive.current = true;
        clickable.current = false;
        switch (step) {
            case 2:
                // Alte Szene ausblenden
                TweenLite.to(h1Ref.current, { x: -50, y: -150, autoAlpha: 0 });
                TweenLite.to(textRef.current, { x: -50, y: -100, autoAlpha: 0 });
                TweenLite.to(imageRef.current, { scale: .75, y: -50, autoAlpha: 0 });

                // Neue Szene einblenden
                TweenLite.from(h1Ref2.current, { x: 50, y: 70, autoAlpha: 0, duration: durationFactor, delay: delayFactor });
                TweenLite.from(textRef2.current, { x: 50, y: 100, autoAlpha: 0, duration: durationFactor, delay: delayFactor });
                TweenLite.from(imageRef2.current, { scale: .75, y: 100, autoAlpha: 0, duration: durationFactor, delay: delayFactor });
                TweenLite.to(swingerRef.current, {
                    xPercent: -100, rotation: 90, duration: 1.5, onComplete: () => {
                        animationActive.current = false;
                        clickable.current = true;
                        resetElements([h1Ref.current, textRef.current, imageRef.current]);
                    }
                });
                break;
            default: // Standard Introanimation
                if (entered.current === true) {
                    TweenLite.to(h1Ref2.current, { x: 50, y: 150, autoAlpha: 0 });
                    TweenLite.to(textRef2.current, { x: 50, y: 100, autoAlpha: 0 });
                    TweenLite.to(imageRef2.current, { scale: .75, y: 50, autoAlpha: 0 });
                }
                TweenLite.from(h1Ref.current, { x: -150, y: -50, autoAlpha: 0 });
                TweenLite.from(textRef.current, { x: -150, y: 50, autoAlpha: 0 });
                TweenLite.from(imageRef.current, {
                    x: -150, y: -50, autoAlpha: 0, onComplete: () => {
                        animationActive.current = false;
                        if (entered.current === false) {
                            clickable.current = true;
                        }
                        entered.current = true;
                    }
                });
                if (entered.current) {// Zurückanimation
                    TweenLite.to(swingerRef.current, {
                        ease: animationType, xPercent: 0, rotation: 0, duration: 1.5, onComplete: () => {
                            clickable.current = true;
                            resetElements([h1Ref2.current, textRef2.current, imageRef2.current]);
                        }
                    })
                } else { // Startanimation
                    TweenLite.from(swingerRef.current, { ease: animationType, x: 300, autoAlpha: 0, duration: 1 })
                }
                break;


        }
    }, []);

    // Scrollt zur nächsten Section
    const scrollToRef = (ref) => {
        let offset = ref.current.offsetTop + 108;
        if (currentScrollPos.current === 2) { // Bis footer
            offset += 180;
        }
        TweenLite.to(window, { duration: 1.5, scrollTo: offset });

    };

    // Initialisierung
    useEffect(() => {
        if (doAnimate()) {
            document.body.classList.add('noscroll');
            TweenLite.defaultEase = animationType;

            if (rendered !== true) {
                setRendered(true);
                setTimeout(() => {
                    scrollEffect(1);

                    buildScrollTriggers(scrollRefs, scrollEffect, scrollToRef, currentScrollPos, animationActive);
                }, 500);
                setTimeout(() => {
                    document.body.classList.remove('noscroll');
                }, 2000)
            }
            return () => {
                ScrollTrigger.getAll().forEach(t => t.kill());
            }
        }
    }, [scrollEffect, scrollRefs, rendered])

    const clickHandler = () => {
        if (clickable.current === false || !doAnimate()) return
        currentScrollPos.current += 1
        scrollEffect(currentScrollPos.current);
        scrollToRef(scrollRefs[currentScrollPos.current - 1])
    }

    return (
        <div>
            <Layout>
                <SEO title={translations.cloud_title} description={translations.cloud_meta_description} />
                <div className="cloud subpage">
                    <div className="lightholder" ref={lightHolderRef}></div>
                    <section ref={scrollRef1}>
                        <div className="swinger animationElement" ref={swingerRef}></div>
                        <div className="inner-wrapper flex">
                            <div>
                                <div ref={h1Ref} className="animationElement">
                                    <h1>
                                        {
                                            nl2br(translations.cloud_1_white_headline)
                                        }<br />
                                        <span className="yellow">
                                            {nl2br(translations.cloud_1_yellow_headline)}
                                        </span></h1>
                                    <p className="grey mb">
                                        {
                                            nl2br(translations.cloud_1_subline)
                                        }
                                    </p>
                                </div>
                                <Image img={CloudMachinesImageMobile} img2={CloudMachinesImageMobileLarge} alt="Cloud Machines" styles="mobile hovergrow scale-80" />
                                <div ref={textRef} className="animationElement">
                                    <p className="light">
                                        {
                                            nl2br(translations.cloud_1_description)
                                        }
                                    </p>
                                </div>
                            </div>
                            <div ref={imageRef} className="image-holder animationElement" >
                                <ImageRef img={CloudMachinesIMacImage} img2={CloudMachinesIMacImageLarge} alt="Cloud Machines" className="hovergrow mobile-exclude" />
                                <ImageRef img={CloudMachinesIPhoneImage} img2={CloudMachinesIPhoneImageLarge} alt="Cloud Machines" className="cloudimage hovergrow mobile-exclude" />
                            </div>
                        </div>
                    </section>
                    <section ref={scrollRef2}>
                        <div className="inner-wrapper flex">
                            <div ref={imageRef2} className="animationElement image-holder">
                                <ImageRef img={CloudShopMacbookImage} img2={CloudShopMacbookImageLarge} alt="Cloud Shop iMac" className="hovergrow mobile-exclude" />
                                <ImageRef img={CloudShopIPhoneImage} img2={CloudShopIPhoneImageLarge} alt="Cloud Shop iPhone" className="cloudimage hovergrow down mobile-exclude" />
                            </div>
                            <div>
                                <div ref={h1Ref2}>
                                    <div className='bs-headline'>
                                        {
                                            nl2br(translations.cloud_2_white_headline)
                                        }<br />
                                        <span className="yellow">
                                            {
                                                nl2br(translations.cloud_2_yellow_headline)
                                            }
                                        </span>
                                    </div>
                                    <p className="grey mt mb">
                                        {
                                            nl2br(translations.cloud_2_subline)
                                        }
                                    </p>
                                </div>
                                <Image img={CloudShopImageMobile} img2={CloudShopImageMobileLarge} alt="Cloud Machines" styles="mobile hovergrow mobile scale-80" />
                                <div ref={textRef2}>
                                    <p className="light ">
                                        {
                                            nl2br(translations.cloud_2_description)
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <Jumpbutton clickHandler={clickHandler} />
            </Layout>
        </div>
    )
}


const mapStateToProps = state => ({
    translations: state.preferences.translations,
    lang: state.preferences.lang,
    serverCall: state.preferences.serverCall,
})

export default connect(mapStateToProps)(Cloud)
